@import '~@ionic/angular/css/core.css';
@import 'sass/styles';

.md ion-item {
  --padding-start: 2px;
}
.ios .error-message {
  margin-left: 22px;
}

.positionInherit {
  position: inherit !important;
}

.a {
  cursor: pointer;
}

//new css
.ion-search {
  --height: 60px;
  --border-radius: 20px;
  --box-shadow: 0 0 12px 0 rgba(218, 233, 218, 0.8);
  border: 1px solid
    linear-gradient(
      to top,
      #ffffff,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
  padding: 0;
  margin-top: 10px;
  border-radius: 20px;

  .searchbar-input-container.sc-ion-searchbar-ios {
    height: 100%;
  }

  .searchbar-input.sc-ion-searchbar-md {
    height: 60px;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    top: 19px;
  }

  .searchbar-input.sc-ion-searchbar-md {
    color: #000;
  }

  .searchbar-clear-icon {
    display: none !important;
  }
}
